import { Injectable } from '@angular/core';
import {User} from './classes/index';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor() { }

  saveUser(data: Object) {
    localStorage.setItem('currentOssAgent', JSON.stringify(data));
  }

  setLoggedStatus(status : Boolean){
    localStorage.setItem('isOssAgentLogged', status+'');
  }

  getLoggedStatus(){
    return (localStorage.getItem('isOssAgentLogged') === 'true') ? true : false;
  }

  getUser(): User{
    return new User(JSON.parse(localStorage.getItem('currentOssAgent'))) ;
  }

  deleteUser(){
    localStorage.setItem('currentOssAgent', 'null');
  }

  getLang() {
    return localStorage.getItem('lang');
  }
}
