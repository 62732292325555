import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User, FormValue} from '../classes';
import { HelperService } from '../helper.service';
import { HttpService } from '../http.service';
import { StoreService } from '../store.service';
// import slide in/out animation
import { slideInOutAnimation2 } from '../_animations/index';
import { Md5 } from 'ts-md5';
import { environment } from 'src/environments/environment';
import { cdnService } from '../cdn.service';
declare var $:any;

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation2],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation2]': '' }
})
export class ServiceComponent implements OnInit {

  modalTitle: string;
  modalText: string;
  modalType: string;
  product = null;
  service: any;
  loading = true;
  lang: string;
  withCode = false;
  withMobile = false;
  fees: number;
  user: User;
  billid: string;
  billidConfirm: FormControl;
  mobile: FormControl;

  amountError = false;
  billidError = false;
  billidConfirmError = false;
  mobileError = false;

  country_code = '237';
  phoneLength = 9;
  pinCode: string;

  total: number;
  urlReq: string;

  pPadVisible = false;
  numpad = [];
  refDetails: any;
  countries = [];

  action_out = false;

  manual = false;
  pinCode_temp : string;
  amount : string;
  formValues : Array<FormValue>;


  current_phone_name : string;
  disable_btn = false;

  file_error : string;
  preauthData : any;
  currencies = [];
  forms = [];

  constructor(private http: HttpService, private helper: HelperService, private store: StoreService,
    private translate: TranslateService, private router: Router, private cdn : cdnService) {}

  ngOnInit() {
    this.product = window.history.state.product;
    this.user = this.store.getUser();
    this.service = window.history.state.service;
    this.billid = window.history.state.billid;
    this.amount = window.history.state.amount;
    this.forms = this.service.forms;


    if(!this.product || !this.service) this.router.navigateByUrl('/home');

    this.lang = localStorage.getItem('lang');
    this.pinCode = "";
    this.getCountries();
    this.formValues = [];

    // for(var i=0; i <= this.service.forms.length; i++){
    //   let f = this.forms[i];
    //   if(f.type == 'select'){ console.log(f);
    //     f.options = this.getItems(f.options);
    //   }

    //   this.forms.push(f);
    // }

    // console.log(this.forms);
  }

  setModal(type : string, text : string, title : string) {

    this.modalType = type;
    this.modalTitle = title;

    switch (type) {
      case 'danger': {
        this.modalText = text;
        break;
      }

      default:{
        this.translate.get(text).subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }
    }

  }

  getCountries(){
    this.http.getCountries().subscribe((response : any) => {
      this.loading = false;
      if(response){
       if(response.code === 200){
        this.countries = response.result;
        this.getCCiesItem();
       }
      }
    })
  }


  getName(service: any) {
    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        return service.translations[this.lang].name || service.name;
      }
    }
    return service.name;
  }

  _cleanString(str: any) {
    return str.replace(/\s/g, '');
  }

  _numberise(str: any) {
    str = this._cleanString(str);

    var output = '';

    for (var i = 0; i < str.length; i++) {
      if (!isNaN(str[i])) output += str[i];
    }

    return output;
  }

  addDigit(d: string) {
    if (this.pinCode.length < 4) {
      this.pinCode = this.pinCode + d
    }
  }

  deleteDigit() {
    (this.pinCode.length > 0) ? this.pinCode = this.pinCode.substring(0, this.pinCode.length - 1): null;
  }

  togglePad() {
    this.pPadVisible = !this.pPadVisible;
    this.numpad = this.helper.getNumpad();
    this.pinCode = "";
  }

  isMobile(){
    return this.helper.isMobile();
  }

  dismissModal(timer: number, destination) {
    setTimeout(() => {
      $('#modalFormPayment').modal('hide');
      $('#modalBillsAlert').modal('hide');
      $('#modalBillAlert').modal('hide');
      if(destination) {
        this.router.navigateByUrl(destination);
        return;
      }
      window.history.back();
    }, timer);
  }

  getType(e){

    switch (e) {
        case "tel" : return "phone-pad"; break;
        case "number" : return "number-pad"; break;
        case "email" : return "email-address"; break;
        case "date" : return "date"; break;
        default : return "default";
    }
  }

  getModel(type) {

		switch(type) {
			case 'file' : return 'file'
			case 'currency' : return 'currency'
			case 'inttel' : return 'inttel'
			case 'country' : return 'country'
			case 'select' : return 'select'
			case 'textarea' : return 'textarea'
			default : return "input";
		}

  }

  getItems(s){
    let options = [];
    if(!s) return [];
    let list = s.split(";");
    for (let index = 0; index < list.length; index++) {
      let val = list[index].split(":");
      options.push({
        label : val[0],
        value : val[1]
      })
    }

    console.log(options);

    return options;
  }

	getLabel(input){
		if(!input) return '';

		let defaultLabel = input.label;
		let label = null;
		if(input.hasOwnProperty('translations')){
			let translations = input.translations;
			if(translations) {
				if(translations[this.lang]) {
					label = translations[this.lang].label;
				}
			}
		}

		return label || defaultLabel;
  }

  getCCiesItem() {
		let items = [];

		this.countries.forEach(e => {
			let country_name = e.translations.hasOwnProperty(this.lang) ? e.translations[this.lang].name : e.name;

			let currencyObj = e.hasOwnProperty('currency') ? e.currency : null;
				let currency_name = '';
				let currency = '';

				if(!(currencyObj == null)) {
					currency = currencyObj.isoL;
					currency_name = currencyObj.name
				}

				items.push({
					label :country_name + " - "+  currency_name,
					value : currency
				})
		})

		this.currencies = items;
  }

  getCurrency(country){
    let c = null;

    let currencyObj =  country.hasOwnProperty('currency') ? country.currency : null;

		if(!(currencyObj == null)) {
			c = currencyObj.isoL;
    }

    return c;

  }


  getBase64(event) {
    const me = this;
    const file = event.target.files[0];
    const reader = new FileReader();

    const name = file.name;
    const lastDot = name.lastIndexOf('.');
    const ext = name.substring(lastDot + 1);

    var filesize = ((file.size/1024)/1024).toFixed(4);

    if(parseFloat(filesize) > 2) {
      this.file_error = "exceed_file_size";
      $("#modalFileAlert").modal("show");

      return new Promise((resolve, reject) => {
        resolve(null)
        reader.onerror = (error) => reject(error)
      });
    }

    return new Promise((resolve, reject) => {
     reader.onload = () => {
        const encode = reader.result.toString();
        encode.replace(/^data:(.*,)?/, '');
        resolve(encode);
     };
     reader.onerror = (error) => reject(error);
     reader.readAsDataURL(file);
    });
}

  getIntTelValue(name, val) {
    const elt_id = "#iso"+name;
    return $(elt_id).attr("iso").toUpperCase()+ $(elt_id).text().substring(1)+val;
  }


  onChangeText($event: any, input: any) {
    var val = $event.target.value;

    if(input.type == 'inttel'){
      val = this.getIntTelValue(input.name,val);
      this.setValue(input.name, val);
    }else {
      if(input.type == 'file') {
        this.getBase64($event).then((e : string) => {
          if(e) {
            this.modalText="uploading";
            this.modalType ="processing";
            $("#modalUpload").modal("show");
            return this.cdn.upload(this.user.agent.idagent, e).subscribe((response : any) => {
              if(response.code == 200){
                this.setValue(input.name, response.result.link);
                this.modalText="upload_complete";
                this.modalType ="success";
              }else {
                this.modalText="upload_failed";
                this.modalType ="failed";
              }
            })
          }
        }, (error : any) => {
          this.file_error = "file_not_upload";
          $("#modalFileAlert").modal("show");
          console.log(error)
        });
      }else {
        this.setValue(input.name, val);
      }
    }

  }


  getValue(formInput){
    let key = formInput.name;

		for(var i = 0; i < this.formValues.length; i++){
			if(this.formValues[i].getName() === key) {
        let output = this.formValues[i].getValue().trim();
        return output.length > 0 ? output : null;
			}
    }

    return null;
  }

  setValue(key, val) {console.log(val);
		for(var i = 0; i < this.formValues.length; i++){
			if(this.formValues[i].getName() === key) {
				this.formValues[i].setValue(val);
				return;
			}
    }

    let fv = new FormValue();
    fv.setName(key);
    fv.setValue(val);

		this.formValues.push(fv);
  }

  searchType(key){

    let form = this.forms;
    let type = "";

		for(var i = 0; i < form.length; i++){
			if(form[i].name === key) {
        type = form[i].type;
			}
    }

    return type;
  }



  selectCountry(country : any){
    this.country_code = country.code;
    const elt_id = "#iso"+this.current_phone_name;
    $(elt_id).text("+"+country.code);
    $(elt_id).attr("iso", country.isoL);
    $('#modalCountry').modal('hide');

    //this.setValue(this.current_phone_name, country.isoL+country.code+$("input[name='"+this.current_phone_name+"']").val())
    this.setValue(this.current_phone_name, country.code+$("input[name='"+this.current_phone_name+"']").val());
  }

  changeCountry(input){
    this.current_phone_name = input.name;
    $('#modalCountry').modal('show');
  }

  resetError(input){
    const elt_id = "#"+input.name+"Error";
    $(elt_id).addClass("d-none");
  }

  getFormData(operation){
    let form = {};

		this.formValues.forEach(e => {
			if(operation == 'preauth') {
        if(this.searchType(e.getName()) !== 'file'){
          form[e.getName()] = e.getValue();
        }
      } else {
        form[e.getName()] = e.getValue();
      }
		});

		return form;
  }


  check(){
    let bool = true;

    this.forms.forEach((e : any) => {
      let regex = e.regex.slice(1);
      let value = this.getValue(e);
			regex = regex.substring(0, regex.length - 1);
      regex = new RegExp(regex, '');
      const elt_error = "#"+e.name+"Error";

      if(this.getValue(e) == null) {
        if(e.required){
          $(elt_error).removeClass("d-none");
          bool = false;
        }
      } else {
        var isMatching = value.match(regex) ? true : false;
        if(!isMatching) {
          $(elt_error).show();
        }
      }
      console.log(e.name + " - "+ value + ' - '+ bool);
    })

    if(bool) {
      this.preAuth();
    }
  }


  preAuth() {

    this.setModal('processing', 'please_wait', 'processing');
    $("#modalFormPayment").modal("show");

		let reference = this.billid ? this.billid : 'NOREF'+this.user.phonecompte;
		let form =  this.getFormData('preauth');
		form =  form ? form : {};
    let amount = this._cleanString(this.amount);

    this.http.getData(this.service.slug, reference, 'preauth', form, amount, this.user.agent.idagent).subscribe((response: any) => {
      if (response) {

        if (response.code === 200) {
          this.preauthData = response.result;
          $("#modalFormPayment").modal("hide");
          $('#modalDetails').modal('show');

        } else {

          this.modalText = response.message;
          $("#modalFormPayment").modal("hide");
          $("#modalBillAlert").modal("show");

        }

      } else {

        this.modalText = "";
        $("#modalFormPayment").modal("hide");
        $("#modalBillAlert").modal("show");

      }
    });

  }

  retry(){
    $("#modalBillAlert").modal("hide");
    this.preAuth();
  }

  execute(){

    if(this.pinCode.length < 4) {
      $("#modalDetails").modal("hide");
      this.togglePad();
      return;
    }


    this.pPadVisible = false;

    this.setModal('processing', 'please_wait', 'processing');
    $("#modalFormPayment").modal("show");

    let data = {
			amount : this._cleanString(this.amount),
			billid : this.billid ? this.billid : 'NOREF'+this.user.phonecompte,
			form : this.getFormData(null),
			code : '',
			biller : this.service.slug,
			account : this.user.phonecompte,
			preauth : null,
      identify : environment.id,
      terminaltype : 'web',
      agentid : this.user.agent.idagent,
      agentpwd : this.user.agent.password
		}

		// data  = Object.assign(data, this.preauthdata);
		data.preauth =  this.preauthData;

    data.code = Md5.hashStr(this.pinCode).toString();

    this.http.exec(
      data,
      this.product.api,
      this.urlReq,
      this.service.slug,
      this.product.category
    ).subscribe((response: any) => {

      this.pinCode = "";

      if (response) {
        if (response.code === 200) {

            this.setModal('success', 'successfull', '');
            this.dismissModal(1500, null);
            this.pinCode = "";

        } else {

          this.modalText = response.message;
          $("#modalFormPayment").modal("hide");
          $("#modalBillAlert").modal("show");
        }
      } else {
        this.modalText = "";
        $("#modalFormPayment").modal("hide");
        $("#modalBillAlert").modal("show");
      }
    });
  }
}
