import {
  Injectable
} from '@angular/core';
import {
  FormGroup
} from '@angular/forms';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})



export class HelperService {

  apikey = '22d9ed00f48dc343b405f51431e54540';

  fr = {
    A: 'Débit/Crédit',
    B: 'Compte vers cash',
    C: 'Facture commeçant',
    D: 'Débit',
    E: 'Débit POS',
    F: 'Dépassement solde',
    G: 'Transfert',
    H: 'Retrait',
    I: 'Cashin',
    J: 'Cash depuis transfert',
    K: 'Avance commeçant',
    L: 'Salaire',
    M: 'Crédit entreprise',
    O: 'Débit Online',
    P: 'Promotion',
    Q: 'Cash vers transfert',
    R: 'Recharge',
    S: 'Dépô agent',
    T: 'Test',
    U: 'Authentification',
    V: 'Ouverture compte client',
    W: 'Recharge',
    X: 'Ext. Gateway',
    Y: 'Paiement de frais',
    Z: 'Paiement agent',
    a: 'Paiement agent (compte de dépôt)',
    b: 'Paiement de facture',
    c: 'Paiement facture commerçant',
    d: 'Vente de dépôt',
    e: 'Achat de dépôt',
    g: 'Régulation',
    i: 'Frais plateforme',
    j: 'Compte vers transfert externe',
    k: 'Espèces vers transfert externe',
    l: 'Transfert distant vers compte',
    m: 'Opération non financière',
    n: 'Transfert distant vers espèces',
    o: 'Retrait distant de transfert externe',
    p: 'Paiement facture en cash',
    r: 'Annulation',
    s: 'Paiement de salaire',
    t: 'Transfert de cash vers compte',
    u: 'Paiement de facture SMS',
    v: 'Nouveau compte client',
    w: 'Transfert vers banque',
    x: 'Forex',
    y: 'Archivage transferts vers banque',
    z: 'Transfert depuis compte bancaire'
  };
  en = {
    A: 'Debit/Credit',
    B: 'Account to cash',
    C: 'Invoice as a reminder',
    D: 'Flow',
    E: 'POS flow',
    F: 'Exceedance of balance',
    G: 'Transfer',
    H: 'Withdrawal',
    I: 'Cashin',
    J: 'Cash since transfer',
    K: 'Advance likewise',
    L: 'Salary',
    M: 'Corporate credit',
    O: 'Debit Online',
    P: 'Promotion',
    Q: 'Cash to transfer',
    R: 'Recharge',
    S: 'Agent Depot',
    T: 'Test',
    U: 'Authentication',
    V: 'Opening a customer account',
    W: 'Recharge',
    X: 'Ext. Gateway',
    Y: 'Payment of fees',
    Z: 'Agent payment',
    a: 'Agent payment (deposit account)',
    b: 'Bill payment',
    c: 'Payment of invoice as invoice',
    d: 'Sale of depot',
    e: 'Purchase of deposit',
    g: 'Regulation',
    i: 'Platform Fees',
    j: 'Account to external transfer',
    k: 'Cash to external transfer',
    l: 'Remote transfer to account',
    m: 'Non-financial transaction',
    n: 'Remote transfer to cash',
    o: 'Remote withdrawal of external transfer',
    p: 'Payment of invoice in cash',
    r: 'Cancellation',
    s: 'Payment of wages',
    t: 'Cash transfer to account',
    u: 'SMS invoice payment',
    v: 'New customer account',
    w: 'Transfer to bank',
    x: 'Forex',
    y: 'Archiving transfers to bank',
    z: 'Transfer from bank account'
  }

  constructor() {}

  isMobile(): boolean {
    var isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
      any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
      }
    };

    return (((isMobile.any()) ? true : false) && (this.getWidth() < 576)) || (this.getWidth() < 576);
  }



  musmatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({
          mustMatch: true
        });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  getNumpad() : Array<string> {
    var array = ['1','2','3','4','5','6','7','8','9','0',''];
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

  stuff(str:string) {
    let output = str;
    const init_length = str.toString().length;
    const steps = 16 - init_length;
    for (let i = 0; i < steps; i++) {
      output += '0';
    }
    return output;
  }

  encrypt(text : string, padding : string){
    var key = CryptoJS.enc.Utf8.parse(this.apikey);
    var iv = CryptoJS.enc.Utf8.parse(this.stuff(padding));
    var encrypted = CryptoJS.AES.encrypt(text, key,
    {
        iv: iv,
        mode : CryptoJS.mode.CBC,
        padding : CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }

  getType(type : string, lang : string) {
    return (lang === 'fr') ? this.fr[type] : this.en[type]
  }

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }
}
