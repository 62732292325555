import { Component, OnInit } from '@angular/core';
import { User } from '../classes';
import { HelperService } from '../helper.service';
import { HttpService } from '../http.service';
import { StoreService } from '../store.service';
import {FormControl} from '@angular/forms';
// import slide in/out animation
import { slideInOutAnimation } from '../_animations/index';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Md5 } from 'ts-md5';
declare var $: any;

@Component({
  selector: 'app-agent-transfer',
  templateUrl: './agent-transfer.component.html',
  styleUrls: ['./agent-transfer.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '' }
})
export class AgentTransferComponent implements OnInit {


  pinCode: string;
  modalTitle: string;
  modalText: string;
  modalType: string;

  isRecipientVisible = false;
  pPadVisible = false;
  numpad = [];


  amount: FormControl;
  agentid: FormControl;

  amountError = false;
  agentidError = false;

  lang : string;
  user : User;
  recipient : string;

  constructor(private helper: HelperService, private store: StoreService,
    private http: HttpService, private translate: TranslateService, private router: Router) { }

  ngOnInit() {
    window.setInterval(() => {this.http.autoLogout();}, 1000);
    this.lang = localStorage.getItem('lang');
    this.user = this.store.getUser();
    this.pinCode = "";
    this.amount = new FormControl("");
    this.agentid = new FormControl("");
  }


  _cleanString(str: any) {
    return str.replace(/\s/g, '');
  }

  _numberise(str: any) {
    str = this._cleanString(str);

    var output = '';

    for (var i = 0; i < str.length; i++) {
      if (!isNaN(str[i])) output += str[i];
    }

    return output;
  }

  onChangeText($event: any, key: string) {
    var value = $event.target.value;
    var output = '';
    var str = this._cleanString(value);


    if (key === 'amount' && value != '0' && parseInt(value) > 0) {
      str = parseInt(this._numberise(str)).toString();
    }

    var rest = str.length % 3;
    var part = Math.trunc(str.length / 3);

    if (str.length > 3) {
      output += str.substring(0, rest);
      str = str.slice(rest);

      for (var i = 0; i < part; i++) {
        output += ' ';
        output += str.substring(0, 3);
        str = str.slice(3);
      }
    } else {
      output = str;
    }

    switch (key) {
      case 'amount': {
        this.amount.setValue(output);
        break;
      }
    }
  }


  resetFormErrors() {
    this.amountError = false;
    this.agentidError = false;
  }


  getName() {

    let bool = true;

    if (!(parseInt(this._cleanString(this.amount.value)) > 0)) {
      this.amountError = true;
      bool = false;
    }

    if(!(this.agentid.value.length === 16)){
      this.agentidError = true;
      bool = false;
    }


    if(bool) {

      let data = {
        agentid : this.agentid.value.trim(),
        agentpwd : this.user.agent.password
      }

      this.setModal('processing', 'please_wait', 'checking');
      $('#modalTransfer').modal('toggle');

      this.http.getAgentName(data).subscribe((response : any )=> {

        if (response) {
          if (response.code === 200) {

            this.recipient = response.result.name;
            $("#modalTransfer").modal("hide");
            $("#modalDetails").modal("show");

          } else {

            this.modalText = response.message;
            $("#modalTransfer").modal("hide");
            $("#modalAlert").modal("show");
          }
        } else {
          this.setModal('warning', 'transaction_failed', '');
          this.dismissModal(2500, null);
        }


      });
    }
  }

  toggleModal(i : number){

    $("#modalDetails").modal("hide");

    if( i == 0){
      this.togglePad();
      return;
    }

    if(i ==1 ){
      if(this.pinCode.length < 4) {
        if(!this.pPadVisible) this.togglePad();
        return;
      }

      this.transfer();
    }

  }


  transfer(){

    let data = {
      agentid : this.user.agent.idagent,
      agentpwd : this.user.agent.password,
      agentreceiver : this.agentid.value.trim(),
      amount : this._cleanString(this.amount.value),
      code : Md5.hashStr(this.pinCode),
      cashincode : Md5.hashStr(this.pinCode)
    }

    this.togglePad();
    $("#modalDetails").modal("hide");


    this.setModal('processing', 'please_wait', 'processing');
    $('#modalTransfer').modal('toggle');

    this.http.agentTransfer(data).subscribe((response : any) => {
      if (response) {
        if (response.code === 200) {

          this.closeOperation();

        } else {

          this.modalText = response.message;
          $("#modalTransfer").modal("hide");
          $("#modalAlert").modal("show");
        }
      } else {
        this.setModal('warning', 'transaction_failed', '');
        this.dismissModal(2500, null);
      }
    })
  }


  closeOperation() {
    this.setModal('success', 'successfull', '');

    this.dismissModal(1500, null);
    this.pinCode = "";

    setTimeout(() => {
      this.router.navigateByUrl('/home');
    }, 2500);

    return;
  }


  setModal(type : string, text : string, title : string) {

    this.modalType = type;
    this.modalTitle = title;

    switch (type) {
      case 'danger': {
        this.modalText = text;
        break;
      }

      default:{
        this.translate.get(text).subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }
    }

  }

  dismissModal(timer: number, dest: string) {
    setTimeout(() => {
      $('#modalTransfer').modal('hide');
      (dest) ? this.router.navigateByUrl(dest): null;
    }, timer);
  }


  addDigit(d: string) {
    if (this.pinCode.length < 4) {
      this.pinCode = this.pinCode + d
    }
  }

  deleteDigit() {
    (this.pinCode.length > 0) ? this.pinCode = this.pinCode.substring(0, this.pinCode.length - 1): null;
  }

  togglePad() {
    this.pPadVisible = !this.pPadVisible;
    this.numpad = this.helper.getNumpad();
    this.pinCode = "";
  }



  isMobile(){
    return this.helper.isMobile();
  }

}
