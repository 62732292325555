import {
  Component,
  OnInit
} from '@angular/core';
// import slide in/out animation  // Last update
import {
  slideInOutAnimation
} from '../_animations/index';
import {
  HttpService
} from '../http.service';
import {
  HelperService
} from '../helper.service';
import {
  FormControl
} from '@angular/forms';
import {
  StoreService
} from '../store.service';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Router
} from '@angular/router';
import {
  User
} from '../classes';
import { Md5 } from 'ts-md5';
declare var $: any;

@Component({
  selector: 'app-add-wallet',
  templateUrl: './add-wallet.component.html',
  styleUrls: ['./add-wallet.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: {
    '[@slideInOutAnimation]': ''
  }
})


export class AddWalletComponent implements OnInit {

  product = null;
  service: any;
  loading = true;
  lang: string;
  withCode = false;
  user: User;

  modalTitle: string;
  modalText: string;
  modalType: string;
  amount = new FormControl('');
  phone = new FormControl('');
  phoneConfirm = new FormControl('');
  code = new FormControl('');
  amountError = false;
  phoneError = false;
  phoneConfirmError = false;
  codeError = false;

  operation = {
    slug: '',
    merchantid: ''
  };

  phoneLength = 9;
  country_code = '237';
  fees = 0;
  fees_agent = 0;
  total = 0;
  pinCode: string;

  isRecipientVisible = false;
  pPadVisible = false;
  numpad = [];

  statusOperation: string;
  totalTime = 0;
  transactionid: number;

  urlReq: string;

  recipient: string;
  withName : false;
  showConfirmBtn = true;
  countries = [];
  operation_title = null;
  wallet = null;
  result: string;

  constructor(private helper: HelperService, private store: StoreService,
    private http: HttpService, private translate: TranslateService, private router: Router) {}

  ngOnInit() {
    this.product = window.history.state.product;
    this.service = window.history.state.service;
    this.operation = window.history.state.operation;
    this.lang = localStorage.getItem('lang');

    if(!this.product || !this.service) this.router.navigateByUrl('/home');

    this.user = this.store.getUser();
    this.pinCode = "";
    this.getCountries();
  }

  getCountries(){
    this.http.getCountries().subscribe((response : any) => {
      if(response){
       if(response.code === 200){
        this.countries = response.result;
       }
      }
    })
  }

  selectCountry(country :any){
    this.country_code = country.code;
    this.phoneLength = country.nationcounttel;
    $('#modalCountry').modal('hide');
  }

  changeCountry(){
    $('#modalCountry').modal('show');
  }

  isPaypalOrCard(){
    return (this.service.type === 'paypal' || this.service.type === 'card');
  }

  isMomo(){
    return this.service.type === 'money';
  }

  getRefLabel(service: any) {
    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        return service.translations[this.lang].reflabel || service.reflabel;
      }
    }
    return service.reflabel;
  }

  getName(service: any) {
    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        return service.translations[this.lang].name || service.name;
      }
    }
    return service.name;
  }



  setModal(type : string, text : string, title : string) {

    this.modalType = type;
    this.modalTitle = title;

    switch (type) {
      case 'danger': {
        this.modalText = text;
        break;
      }

      default:{
        this.translate.get(text).subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }
    }

  }

  dismissModal(timer: number, dest: string) {
    setTimeout(() => {
      $('#modalPayment').modal('hide');
      (dest) ? this.router.navigateByUrl(dest): null;
    }, timer);
  }

  _cleanString(str: any) {
    str = str.replace(/\s/g, '');

    var output = '';

    for (var i = 0; i < str.length; i++) {
      if (!isNaN(str[i])) output += str[i];
    }

    return output;
  }

  onChangeText($event: any, key: string) {
    var value = $event.target.value;
    var output = '';
    var str = this.isPaypalOrCard() ? value : this._cleanString(value);


    if (key === 'amount' && value != '0' && parseInt(value) > 0) {
      str = parseInt(str).toString();
    }

    if(this.isPaypalOrCard()){
      output = str;
    } else {
      var rest = str.length % 3;
      var part = Math.trunc(str.length / 3);

      if (str.length > 3) {
        output += str.substring(0, rest);
        str = str.slice(rest);

        for (var i = 0; i < part; i++) {
          output += ' ';
          output += str.substring(0, 3);
          str = str.slice(3);
        }
      } else {
        output = str;
      }
    }

    switch (key) {
      case 'amount': {
        this.amount.setValue(output);
        break;
      }
      case 'phone': {
        this.phone.setValue(output);
        break;
      }
      case 'phoneConfirm': {
        this.phoneConfirm.setValue(output);
        break;
      }
      case 'code': {
        this.code.setValue(output);
        break;
      }
    }
  }



  resetFormErrors() {
    this.phoneError = false;
    this.phoneConfirmError = false;
  }

  toggleModal() {
    let bool = true;

      var phone = this._cleanString(this.phone.value);
      let regex = this.service.accountregex.toString();
      regex = regex.slice(1);
      regex = regex.substring(0, regex.length - 1);
      regex = new RegExp(regex, '');


      if (this.service.international) {
        if (!(phone.length === this.phoneLength)) {
          bool = bool && false;
          this.phoneError = true;
        }
      }

      if(this.isPaypalOrCard()) {

        var isMatching = this.phone.value.trim().match(regex) ? true : false;
        if (!(isMatching)) {
          bool = bool && false;
          this.phoneError = true;
        }

        if (!(this.phone.value.trim() === this.phoneConfirm.value.trim())) {
          bool = bool && false;
          this.phoneConfirmError = true;
        }
      } else {
        var isMatching = phone.match(regex) ? true : false;
        if (!(isMatching && phone.length > 4)) {
          bool = bool && false;
          this.phoneError = true;
        }

        if (!(this._cleanString(this.phone.value) === this._cleanString(this.phoneConfirm.value))) {
          bool = bool && false;
          this.phoneConfirmError = true;
        }
      }


      if (bool) {
        this.togglePad();
      }
  }



  execute() {

    if(this.pinCode.length < 4  && this.product.need_pin) return;

    let data: {
      [k: string]: any
    } = {};

    if(this.product.zone === 'client') {
			data.client = this.user.phonecompte;
			data.pin = this.helper.encrypt(this.pinCode, data.client);
		} else {
			data.agentid = this.user.agent.idagent;
			data.cashincode = Md5.hashStr(this.pinCode);
		}

    this.togglePad();

    this.setModal('processing', 'please_wait', 'transaction_processing');
    $('#modalPayment').modal('show');


    let cc = this.service.international ? this.country_code : '';
    data.provider = this.service.slug;
    data.account = cc + this.isPaypalOrCard() ? this.phone.value : this._cleanString(this.phone.value);

    this.http.addWallet(data, this.product.zone).subscribe( (response : any) => {

      if(response) {
          if(response.code == 200 ) {
            this.wallet = data;
            this.result = response.result;
            $('#modalPayment').modal('hide');

            $('#modalWalletAlert').modal('show');

          } else {
            this.setModal('danger', response.message, '');
            this.dismissModal(2500, null);
          }

      }else {

          this.setModal('warning', 'failed', '');
          this.dismissModal(2500, null);
      }
    }, (error : any) => {

        this.setModal('warning', 'failed', '');
        this.dismissModal(2500, null);

    })
  }

  confirmWallet(){

		var otp = this._cleanString(this.code.value);

		if(otp.length < 6 ) {
			this.codeError = true;
			return;
		}


    this.setModal('processing', 'please_wait', 'transaction_processing');
    $('#modalPayment').modal('show');



    this.http.confirmWallet(otp, this.result, this.product.zone).subscribe( (response : any) => {

      if(response) {
          if(response.code == 200 ) {

            this.setModal('success', 'successfull', '');
            this.dismissModal(1500, null);

              setTimeout(() => {

                this.service.wallet = this.wallet;

                if(this.isPaypalOrCard() && this.product.category === 'cashout' ) {
                  this.router.navigateByUrl('home/services/payment/async' , {state : {product : this.product, service : this.service}});
                } else {
                  this.router.navigateByUrl('home/services/'+this.product.payment_view , {state : {product : this.product, service : this.service}});
                }

              }, 1500)

          } else {

            this.setModal('danger', response.message, '');
            this.dismissModal(2500, null);

          }

      }else {
        this.setModal('warning', 'failed', '');
        this.dismissModal(2500, null);
      }
      }, (error : any) => {

        this.setModal('warning', 'failed', '');
        this.dismissModal(2500, null);

    })
  }



  addDigit(d: string) {
    if (this.pinCode.length < 4) {
      this.pinCode = this.pinCode + d
    }
  }

  deleteDigit() {
    (this.pinCode.length > 0) ? this.pinCode = this.pinCode.substring(0, this.pinCode.length - 1): null;
  }

  togglePad() {
    this.pPadVisible = !this.pPadVisible;
    this.numpad = this.helper.getNumpad();
    this.pinCode = "";
  }



  isMobile(){
    return this.helper.isMobile();
  }

}
