import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})


export class HeaderComponent implements OnInit {

  currentRoute : string;
  noBackArrow = ['/home', '/account', '/support'];

  constructor(private route : ActivatedRoute, private router : Router) { }

  ngOnInit() {

    this.currentRoute = this.router.url;
  }

  goTo(route : string) : void {
    this.router.navigateByUrl(route);
  }

  goBack(){
    if(this.isHome()) return;
    window.history.back();
  }

  isHome(){
    for(var i=0; i<this.noBackArrow.length; i++){
      if(this.currentRoute === this.noBackArrow[i]) return true;
    }
    return false;
  }

}
