import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpService} from '../http.service';

@Component({
  selector: 'app-bottom-tab',
  templateUrl: './bottom-tab.component.html',
  styleUrls: ['./bottom-tab.component.css']
})
export class BottomTabComponent implements OnInit {

  currentRoute : string;

  constructor(private http: HttpService, private route : ActivatedRoute) { }

  ngOnInit() {
    window.setInterval(() => {this.http.autoLogout();}, 1000);
    const routes = this.route.snapshot.url;
      if (routes.length > 1) {
        this.currentRoute = routes[0] + '/' + routes[1];
      } else {
        if (routes.length === 1) {
            this.currentRoute = routes[0] + '';
          }
      }
  }

}
