export class User {
    password: string;
    phonecompte: string;
    nomcompte: string;
    prenomcompte: string;
    emailcompte: string;
    numidentite: string;
    civilite: string;
    idcosclient: string;
    lang: string;
    datelog: string;
    agent : Agent;

    constructor(data : any){
        this.password = data.password;
        this.phonecompte = data.phonecompte;
        this.prenomcompte = data.prenomcompte;
        this.nomcompte = data.nomcompte;
        this.emailcompte = data.emailcompte;
        this.numidentite = data.numidentite;
        this.civilite = data.civilite;
        this.idcosclient = data.idcosclient;
        this.lang = data.lang;
        this.datelog = data.datelog;
        this.agent = data.agent;
    }


    setAgent(data : any){
        var agent = new Agent(data);
        this.agent = agent;
    }
}


class Agent {
    idagent : string;
    password : string;
    agentname : string;
    username : string;

    constructor(data : any){
        this.idagent = data.idagent;
        this.password = data.password;
        this.agentname = data.agentname;
        this.username = data.username;
    }
}
