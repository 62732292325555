import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
// import slide in/out animation
import { slideInOutAnimation,fadeInAnimation } from '../_animations/index';
import {HttpService} from '../http.service';
import { HelperService } from '../helper.service';
import { User } from '../classes';
import { StoreService } from '../store.service';
declare var $ : any;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation, fadeInAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '', '[@fadeInAnimation]' : '' }
})
export class ServicesComponent implements OnInit {
  product = null;
  services =  null;
  loading  = true ;
  request: string;
  lang : string;
  operations = [];
  operation = null;
  currentRoute : string;
  service : any;
  user : User;
  wallets = [];

  constructor(private http : HttpService, private router : Router, private helper : HelperService, private store : StoreService) { }

  ngOnInit() {
    if(!window.history.state.product) this.router.navigateByUrl('/home');
    this.product = window.history.state.product;
    this.lang = localStorage.getItem('lang');
    this.user = this.store.getUser();
    this.currentRoute = this.router.url;
    this.getServices();
  }

  getName(service : any){
    if(service.hasOwnProperty('translations')){
      if(service.translations.hasOwnProperty(this.lang)){
        return service.translations[this.lang].name || service.name;
      }
    }
    return  service.name;
  }

  goTo(service : any) : void {

    this.service = service;

    if(service.comingsoon){
      $('#modalUnavailable').modal('show');
      setTimeout(() => {
        $('#modalUnavailable').modal('hide');
      }, 2500);
      return;
    }

    let root = 'home';

    if(service.type === 'paypal' && this.product.category === 'cashout') {
      this.router.navigateByUrl('home/services/payment/async' , {state : {product : this.product, service : service}});
      return;
    }

    if(!service.wallet && this.product.category === 'cashout') {

      if(this.isMobile()) {

        this.router.navigateByUrl(root+'/services/wallet/add' , {state : {product : this.product, service : service}});

      } else {

        this.router.navigateByUrl(root+'/wallet/add' , {state : {product : this.product, service : service}});
      }
      return;
    }

    if(this.product.mode === 'client'){
      if(this.isPaypalOrCard() && this.product.category === 'cashout' ) {
        this.router.navigateByUrl(root+'/services/payment/async' , {state : {product : this.product, service : service}});
      } else {
        this.router.navigateByUrl(root+'/services/'+this.product.payment_view , {state : {product : this.product, service : service}});
      }
    }

    if(this.product.mode === 'agent'){
      if(this.product.category === 'school'){
        this.router.navigateByUrl(root+'/services/'+this.product.payment_view , {state : {product : this.product, service : service}});
        return;
      }
      this.operations = service.paymentEndpoints;
      $('#modal').modal('show');
    }

  }

  isPaypalOrCard(){
    return (this.service.type === 'paypal' || this.service.type === 'card');
  }

  goToPayment(operation : any){
    $('#modal').modal('hide');
    this.router.navigateByUrl('home/services/'+this.product.payment_view , {state : {product : this.product, service : this.service, operation : operation}});
  }

  getServices(){
    this.loading = true;
    this.services = null;
    const product = this.product;

    if(this.product.category === 'cashout') {

			var id = ( this.product.zone === 'client') ? this.user.phonecompte : this.user.agent.idagent

			this.http.getWallets(id, this.product.zone).subscribe( (response : any) => {
				if(response){
					if(response.code === 200 ) {
						this.wallets = response.result;

            this.http.getOperators(product.api, product.mode, product.category).subscribe((response : any) => {
              this.services = response.result;
              this.loading = false;
              this.request = '';

              for(let i = 0; i < this.services.length; i++) {
                for (const method of this.wallets) {
                  if(this.services[i].slug === method.provider) {
                    this.services[i].wallet = method;
                    break;
                  }
                }
              }

            })
					}
				}

			})

		} else {

      this.http.getOperators(product.api, product.mode, product.category).subscribe((response : any) => {
        this.services = response.result;
        this.loading = false;
        this.request = '';

        for(let i = 0; i < this.services.length; i++) {
          for (const method of this.wallets) {
            if(this.services[i].slug === method.provider) {
              this.services[i].wallet = method;
              break;
            }
          }
        }
      })

		}
  }



  isMobile(){
    return this.helper.isMobile();
  }

}
