import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ServicesComponent } from './services/services.component';
import { PaymentComponent } from './payment/payment.component';
import { AccountComponent } from './account/account.component';
import { SupportComponent } from './support/support.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PaymentBillComponent } from './payment-bill/payment-bill.component';
import { PosComponent } from './pos/pos.component';
import { LogoutComponent } from './logout/logout.component';
import { guard } from './guard';
import { AboutComponent } from './about/about.component';
import { PayrequestComponent } from './payrequest/payrequest.component';
import { AddWalletComponent } from './add-wallet/add-wallet.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { AsyncPaymentComponent } from './async-payment/async-payment.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HistoryAgentComponent } from './history-agent/history-agent.component';
import { HomeAgentComponent } from './home-agent/home-agent.component';
import { PaymentSubBillsComponent } from './payment-sub-bills/payment-sub-bills.component';
import { ServiceComponent } from './service/service.component';
import { PrinterComponent } from './printer/printer.component';
import { AgentTransferComponent } from './agent-transfer/agent-transfer.component';
import { CategoryComponent } from './category/category.component';
import { HistoryExportComponent } from './history-export/history-export.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', component : LoginComponent},
  { path: 'logout', component : LogoutComponent, canActivate : [guard]},
  { path: 'payment/:status', component : PaymentStatusComponent},
  { path: 'payment/web/:status', component : PaymentStatusComponent},
  { path: 'home', component: HomeAgentComponent, children: [
      { path: 'agent-transfer', component: AgentTransferComponent},
      { path: 'services', component: ServicesComponent , children : [
        { path: 'payment', component: PaymentComponent},
        { path: 'payment/async', component: AsyncPaymentComponent},
        { path: 'printer', component: PrinterComponent},
        { path: 'paybill', component: PaymentBillComponent, children : [
          { path: 'sub-bills', component: PaymentSubBillsComponent},
          { path: 'service', component: ServiceComponent}
        ]},
        { path: 'wallet/add', component: AddWalletComponent},
        { path: 'payment/async/checkout', component: CheckoutComponent}
      ]},
      { path: 'payment', component: PaymentComponent},
      { path: 'payment/async', component: AsyncPaymentComponent},
      { path: 'payment/async/checkout', component: CheckoutComponent},
      { path: 'paybill', component: PaymentBillComponent, children : [
        { path: 'sub-bills', component: PaymentSubBillsComponent},
        { path: 'service', component: ServiceComponent}
      ]},
      {path : 'history', component : HistoryAgentComponent, children :[
        { path: 'export', component: HistoryExportComponent}
      ]},
      {path : 'pos', component : PosComponent},
      { path: 'wallet/add', component: AddWalletComponent},
      {path: 'account', component : AccountComponent, children : [
        {path : 'reset/:type', component : ResetPasswordComponent},
      ]},
      {path: 'support', component : SupportComponent},
      {path: 'about', component : AboutComponent},
      {path : 'payrequest', component : PayrequestComponent},
      { path: 'printer', component: PrinterComponent},
      { path: 'paybill', component: PaymentBillComponent, children : [
        { path: 'sub-bills', component: PaymentSubBillsComponent},
        { path: 'service', component: ServiceComponent}
      ]},
      {path : 'category', component : CategoryComponent, children : [
        { path: 'services', component: ServicesComponent , children : [
          { path: 'printer', component: PrinterComponent}]}
      ]}
    ], canActivate : [guard]
  },
  {path: 'account', component : AccountComponent, children :[
    {path : 'reset/:type', component : ResetPasswordComponent},
  ],  canActivate : [guard]},
  {path: 'support', component : SupportComponent, canActivate : [guard]},
  {path: 'about', component : AboutComponent, canActivate : [guard]},
  {path : 'payrequest', component : PayrequestComponent, canActivate : [guard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})


export class AppRoutingModule { }

// for easy import into app module
export const routedComponents = [
  ServicesComponent,
  PaymentComponent
];
