import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {HttpService} from '../http.service';

@Component({
  selector: 'app-header-agent',
  templateUrl: './header-agent.component.html',
  styleUrls: ['./header-agent.component.css']
})
export class HeaderAgentComponent implements OnInit {

  constructor(private http: HttpService, private router : Router) { }

  ngOnInit() {
    window.setInterval(() => {this.http.autoLogout();}, 1000);
  }

  goTo(route: string): void {
    this.router.navigateByUrl(route);
  }

}
