import {
  Component,
  OnInit
} from '@angular/core';
// import slide in/out animation
import { slideInOutAnimation,fadeInAnimation } from '../_animations/index';
import {
  HttpService
} from '../http.service';
import {
  HelperService
} from '../helper.service';
import {
  StoreService
} from '../store.service';
import {
  User
} from '../classes';
import {
  FormControl
} from '@angular/forms';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Router
} from '@angular/router';
import { Md5 } from 'ts-md5';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-payment-bill',
  templateUrl: './payment-bill.component.html',
  styleUrls: ['./payment-bill.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation, fadeInAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '', '[@fadeInAnimation]' : '' }
})
export class PaymentBillComponent implements OnInit {

  modalTitle: string;
  modalText: string;
  modalType: string;
  product = null;
  service: any;
  loading = true;
  lang: string;
  withCode = false;
  withMobile = false;
  fees: number;
  user: User;
  amount: FormControl;
  billid: FormControl;
  mobile: FormControl;

  amountError = false;
  billidError = false;
  mobileError = false;

  country_code = '237';
  phoneLength = 9;
  pinCode: string;

  total: number;
  urlReq: string;

  pPadVisible = false;
  numpad = [];
  refDetails: any;
  countries = [];

  action_out = false;

  manual = false;
  pinCode_temp : string;

  constructor(private http: HttpService, private helper: HelperService, private store: StoreService,
    private translate: TranslateService, private router: Router) {}

  ngOnInit() {
    this.product = window.history.state.product;
    this.user = this.store.getUser();
    this.service = window.history.state.service;

    if(!this.product || !this.service) this.router.navigateByUrl('/home');

    this.lang = localStorage.getItem('lang');
    this.setWithMobile(this.service);
    this.amount = new FormControl('');
    this.billid = new FormControl('');
    this.mobile = new FormControl('');
    this.pinCode = "";
    this.getCountries();
    this.setActionOut();

    if(this.service.hasOwnProperty("manual")){
      this.manual = this.service.manual;
    }

    if (this.service.withAmount) {
			this.service.withAmount = this.service.withAmount && !this.service.isClassify;
		}
  }

  quit(){
    $("#modalManual").modal("hide");
    setTimeout(() => {
      this.router.navigateByUrl('/home');
    }, 2500);
  }

  setActionOut(){
    this.service.actions.forEach(action => {
      if(action.type === 'out') this.action_out = true;
    });
  }

  getCountries(){
    this.http.getCountries().subscribe((response : any) => {
      if(response){
       if(response.code === 200){
        this.countries = response.result;
       }
      }
    })
  }

  selectCountry(country :any){
    this.country_code = country.code;
    this.phoneLength = country.nationcounttel;
    $('#modalCountry').modal('hide');
  }

  changeCountry(){
    $('#modalCountry').modal('show');
  }


  setWithMobile(service: any) {
    if (service.hasOwnProperty('withmobile')) {
      this.withMobile = service.withmobile;
    }
  }

  getInstructions(service: any) {
    if (service.instructionscode && (this.product.category === 'cashout') && !(this.product.mode === 'agent')) {
      return service.instructionscode;
    }

    return null;
  }

  getRefLabel(service: any) {
    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        return service.translations[this.lang].reflabel || service.reflabel;
      }
    }
    return service.reflabel;
  }

  getName(service: any) {
    if(!service) return null;

    let name = service.name;

    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        name = service.translations[this.lang].name || service.name;
      }
    }
    return name ? name.length > 0 ? name : null : null;
  }

  _cleanString(str: any) {
    return str.replace(/\s/g, '');
  }

  _numberise(str: any) {
    str = this._cleanString(str);

    var output = '';

    for (var i = 0; i < str.length; i++) {
      if (!isNaN(str[i])) output += str[i];
    }

    return output;
  }

  onChangeText($event: any, key: string) {
    var value = $event.target.value;
    var output = '';
    var str = this._cleanString(value);


    if (key === 'amount' && value != '0' && parseInt(value) > 0) {
      str = parseInt(this._numberise(str)).toString();
    }

    var rest = str.length % 3;
    var part = Math.trunc(str.length / 3);

    if (str.length > 3) {
      output += str.substring(0, rest);
      str = str.slice(rest);

      for (var i = 0; i < part; i++) {
        output += ' ';
        output += str.substring(0, 3);
        str = str.slice(3);
      }
    } else {
      output = str;
    }

    switch (key) {
      case 'amount': {
        this.amount.setValue(output);
        break;
      }
    }
  }



  toggleModal(action) {
    if (action === 2) {
      let bool = true;

      if (this.service.withAmount) {
        if (!(parseFloat(this._cleanString(this.amount.value)) > 0)) {
          bool = bool && false;
          this.amountError = true;
        }
      }

      if (this.service.withRef) {
        var billid = this._cleanString(this.billid.value);
        let regex = this.service.accountregex.toString();
        regex = regex.slice(1);
        regex = regex.substring(0, regex.length - 1);
        regex = new RegExp(regex, '');
        var isMatching = billid.match(regex) ? true : false;
        if (!isMatching) {
          bool = bool && false;
          this.billidError = true;
        }
      }

      if (this.withMobile) {
        var phone = this._cleanString(this.mobile.value);
        if (!(phone.length === this.phoneLength)) {
          bool = bool && false;
          this.mobileError = true;
        }
      }

      if (bool) {

        if(this.service.optionform) {

          this.router.navigateByUrl('home/services/paybill/service', {state : {service: this.service , product : this.product, billid : this.billid.value.trim(), amount : this.amount.value}});

          return;
				}

        if(this.service.isClassify) {

          this.router.navigateByUrl('home/services/paybill/sub-bills', {state : {service: this.service , product : this.product, billid : this.billid.value.trim()}});

				} else {
					this.togglePad();
				}
      }
    } else {
      if (action === 0) {
        this.togglePad();
        this.pinCode = "";
      } else {
        if (action === 1) {
          this.execute();
        }
      }
    }
  }

  setModal(type : string, text : string, title : string) {

    this.modalType = type;
    this.modalTitle = title;

    switch (type) {
      case 'danger': {
        this.modalText = text;
        break;
      }

      default:{
        this.translate.get(text).subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }
    }

  }

  dismissModal(timer: number, dest: string) {
    setTimeout(() => {
      $('#modalBillPayment').modal('hide');
      (dest) ? this.router.navigateByUrl(dest): null;
    }, timer);
  }


  extractFees(debit) {
    if (Array.isArray(debit)) {
      for (var i = 0; i < debit.length; i++) {
        if (debit[i].hasOwnProperty(this.product.mode)) return debit[i].amount;
      }
    } else {
      return debit.amount;
    }

    return null;
  }

  evaluateFees() {
    let data: {
      [k: string]: any
    } = {};
    data.type = 'client_api_paybill';
    data.client1 = null;
    data.merchantid = this.service.merchantid;
    data.currency = 952;
    data.amount = parseInt(this._cleanString(this.amount.value));
    data.agentid = this.user.agent.idagent;

    if (data.amount > 0) {
      this.setModal('processing', 'please_wait', 'evaluation');
      $('#modalBillPayment').modal('toggle');

      this.http.getFees(data).subscribe((response: any) => {
        if (response) {
          if (response.credit) {
            if (this.extractFees(response.credit) !== null) {
              this.fees = Math.ceil(this.extractFees(response.credit));
            }
          }
        }
        this.dismissModal(1000, null);
      });
    } else {
      this.amountError = true;
    }
  }

  retry() {
    this.pinCode = this.pinCode_temp;
    setTimeout(() => {
      this.execute();
    }, 300)
  }

  cancel() {
    this.pinCode = '';
  }

  execute() {

    if(this.pinCode.length < 4) return;

    let data: {
      [k: string]: any
    } = {};

    this.pinCode_temp = this.pinCode;
    data.code = Md5.hashStr(this.pinCode).toString();
    if(this.pPadVisible) this.togglePad();
    data.biller = this.service.slug;
    data.billid = this._cleanString(this.billid.value);
    data.amount = parseInt(this._cleanString(this.amount.value));
    data.account = this.user.phonecompte;
    data.mobile = this.country_code + this._cleanString(this.mobile.value);
    data.currency = 952;
		data.identify = environment.id;
		data.terminaltype = 'web';
    data.agentid = this.user.agent.idagent;
    data.agentpwd = this.user.agent.password;

    this.setModal('processing', 'please_wait', 'transaction_processing');
    $('#modalBillPayment').modal('toggle');

    this.http.exec(
      data,
      this.product.api,
      this.urlReq,
      this.service.slug,
      this.product.category
    ).subscribe((response: any) => {
      if (response) {
        if (response.code === 200) {

          if(this.manual){

          } else {
            this.setModal('success', 'successfull', '');

            this.dismissModal(1500, null);
            this.pinCode = "";

            if (!this.action_out) {
              this.dismissModal(2500, 'home');
            }
          }


        } else {

          this.modalText = response.message;
          $("#modalBillPayment").modal("hide");
          $("#modalAlert").modal("show");
        }
      } else {
        this.setModal('danger', 'failed', '');
        this.dismissModal(2500, null);
      }
    });
  }


  addDigit(d: string) {
    if (this.pinCode.length < 4) {
      this.pinCode = this.pinCode + d
    }
  }

  deleteDigit() {
    (this.pinCode.length > 0) ? this.pinCode = this.pinCode.substring(0, this.pinCode.length - 1): null;
  }

  togglePad() {
    this.pPadVisible = !this.pPadVisible;
    this.numpad = this.helper.getNumpad();
    this.pinCode = "";
  }

  resetFormErrors() {
    this.amountError = false;
    this.mobileError = false;
    this.billidError = false;
  }

  getInvoice(action: any) {

    var bool = true;

    var billid = this._cleanString(this.billid.value);
    let regex = this.service.accountregex.toString();
    regex = regex.slice(1);
    regex = regex.substring(0, regex.length - 1);
    regex = new RegExp(regex, '');
    var isMatching = billid.match(regex) ? true : false;
    if (!isMatching) {
      bool = bool && false;
      this.billidError = true;
    }


    if (bool) this.http.print(action.endpoint, billid, this.service.slug, this.product.category);
  }

  getInfos = (action: any) => {
    var bool = true;

    var billid = this._cleanString(this.billid.value);
    let regex = this.service.accountregex.toString();
    regex = regex.slice(1);
    regex = regex.substring(0, regex.length - 1);
    regex = new RegExp(regex, '');
    var isMatching = billid.match(regex) ? true : false;
    if (!isMatching) {
      bool = bool && false;
      this.billidError = true;
    }

    if (bool) {
      this.setModal('processing', 'please_wait', 'transaction_processing');
      $('#modalBillPayment').modal('toggle');

      this.http.getData(this.service.slug, this.billid.value,  action.slug).subscribe((response: any) => {
        if (response) {
          if (response.code === 200) {

            this.refDetails = response.result;
            this.dismissModal(1000, null);

            $('#modalDetails').modal('show');
          } else {

            this.modalText = response.message;
            $("#modalBillPayment").modal("hide");
            $("#modalAlert").modal("show");
          }
        } else {
          this.setModal('success', 'successfull', '');
          this.dismissModal(2500, null);
        }
      });
    }
  }

  do(action: any) {
    (action.type === 'in') ? this.getInfos(action): (action.type === 'out') ? this.getInvoice(action) : null;
  }

  isMobile(){
    return this.helper.isMobile();
  }

}
