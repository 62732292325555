import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {environment} from '../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css']
})
export class PaymentStatusComponent implements OnInit {

  status : string;
  currentRoute : string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private route : ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      var s = params.get('status');
      if(s === 'success' || s === 'failed') this.status = s;
      if(this.status) $('#myModal').modal('show');
      if(!this.status) this.router.navigateByUrl('/home');
    });

    const routes = this.route.snapshot.url;
      if (routes.length > 1) {
        this.currentRoute = routes[0] + '/' + routes[1];
      } else {
        if (routes.length === 1) {
            this.currentRoute = routes[0] + '';
          }
      }
  }

  goHome() {
    window.parent.window.postMessage({"for":"user","data":"anything"}, environment.url);
    if (this.currentRoute === "payment/web") this.router.navigateByUrl('/home');
  }

}
