import {
  Component,
  OnInit
} from '@angular/core';
// import slide in/out animation  // Last update
import {
  slideInOutAnimation
} from '../_animations/index';
import {
  HttpService
} from '../http.service';
import {
  HelperService
} from '../helper.service';
import {
  FormControl
} from '@angular/forms';
import {
  StoreService
} from '../store.service';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Router
} from '@angular/router';
import {
  User
} from '../classes';
import { Md5 } from 'ts-md5';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: {
    '[@slideInOutAnimation]': ''
  }
})
export class PaymentComponent implements OnInit {

  product = null;
  service: any;
  loading = true;
  lang: string;
  withCode = false;
  user: User;

  modalTitle: string;
  modalText: string;
  modalType: string;
  amount = new FormControl('');
  phone = new FormControl('');
  filledPhone = new FormControl('');
  phoneConfirm = new FormControl('');
  code = new FormControl('');
  amountError = false;
  phoneError = false;
  phoneConfirmError = false;
  codeError = false;

  operation = {
    slug: '',
    merchantid: '',
    getCode : false,
    urlgetcode : '',
    instructionscode : '',
    translations : null
  };

  phoneLength = 9;
  country_code = '237';
  fees = 0;
  fees_agent = 0;
  total = 0;
  pinCode: string;

  isRecipientVisible = false;
  pPadVisible = false;
  numpad = [];

  statusOperation: string;
  totalTime = 0;
  transactionid: number;

  urlReq: string;

  recipient: string;
  withName : false;
  showConfirmBtn = true;
  countries = [];
  operation_title = null;
  getCode = false;
  urlgetcode = "";
  instructions = "";

  constructor(private helper: HelperService, private store: StoreService,
    private http: HttpService, private translate: TranslateService, private router: Router) {}

  ngOnInit() {
    this.product = window.history.state.product;
    this.service = window.history.state.service;
    this.operation = window.history.state.operation;
    this.lang = localStorage.getItem('lang');

    if(!this.product || !this.service) this.router.navigateByUrl('/home');
    if(this.product.operations && !this.operation) this.router.navigateByUrl('/home');
    if(this.operation) this.setDescription(this.operation);

    this.user = this.store.getUser();
    this.setWithcode(this.service);
    this.setWithName(this.service);
    this.pinCode = "";
    this.getCountries();

    if(this.service.wallet) {
      this.filledPhone.setValue(this.service.wallet.account);
      this.phone.setValue(this.service.wallet.account);
      this.phoneConfirm.setValue(this.service.wallet.account);
    }

    if(this.operation) {
      this.getCode  = this.operation.getCode;
      this.urlgetcode  =  this.operation.urlgetcode;
      this.extractInstructions(this.operation);
    } else {
      this.setGetCode(this.service);
    }
  }

  extractInstructions(e) {
    this.instructions = e.instructionscode;
    let translations = e.translations;
    if(translations[this.lang]){
      this.instructions =  translations[this.lang].instructionscode;
    }

    if(this.instructions){
      var temp =  this.instructions.split('\\n');
      this.instructions = temp.join("<br>");
    }
  }

  setGetCode(service){
		const pE = service.paymentEndpoints;
		if(Array.isArray(pE)){
			for(var i = 0;  i < pE.length; i++) {
				if(pE[i].slug == 'cashout') {
					this.getCode =  pE[i].getCode;
          this.urlgetcode =  pE[i].urlgetcode;
          this.extractInstructions(pE[i]);
				}
			}
		}
	}

  isPaypal(){
    return this.service.type === 'paypal';
  }

  getCountries(){
    this.http.getCountries().subscribe((response : any) => {
      if(response){
       if(response.code === 200){
        this.countries = response.result;
       }
      }
    })
  }

  selectCountry(country :any){
    this.country_code = country.code;
    this.phoneLength = country.nationcounttel;
    $('#modalCountry').modal('hide');
  }

  changeCountry(){
    $('#modalCountry').modal('show');
  }


  setWithcode(service: any) {
    if (service.hasOwnProperty('class')) {
      if (service.class.hasOwnProperty('withCode')) this.withCode = service.class.withCode;
    }

    this.withCode = this.withCode && this.product.need_code
  }

  setWithName(service:any){
    if (service.hasOwnProperty('withName')) {
      this.withName = service.withName && this.product.withname;
    }
  }

  getInstructions(service: any) {
    if (service.instructionscode && (this.product.category === 'cashout') && !(this.product.zone === 'agent')) {
      return service.instructionscode;
    }

    return null;
  }

  getRefLabel(service: any) {
    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        return service.translations[this.lang].reflabel || service.reflabel;
      }
    }
    return service.reflabel;
  }

  getName(service: any) {
    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        return service.translations[this.lang].name || service.name;
      }
    }
    return service.name;
  }

  setDescription(operation: any) {
    if (operation.hasOwnProperty('translations')) {
      if (operation.translations.hasOwnProperty(this.lang)) {
        this.operation_title = operation.translations[this.lang].description;
      }
    }
  }


  setModal(type : string, text : string, title : string) {

    this.modalType = type;
    this.modalTitle = title;

    switch (type) {
      case 'danger': {
        this.modalText = text;
        break;
      }

      default:{
        this.translate.get(text).subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }
    }

  }

  dismissModal(timer: number, dest: string) {
    setTimeout(() => {
      $('#modalPayment').modal('hide');
      (dest) ? this.router.navigateByUrl(dest): null;
    }, timer);
  }

  _cleanString(str: any) {
    str = str.replace(/\s/g, '');

    var output = '';

    for (var i = 0; i < str.length; i++) {
      if (!isNaN(str[i])) output += str[i];
    }

    return output;
  }

  onChangeText($event: any, key: string) {
    var value = $event.target.value;
    var output = '';
    var str = this._cleanString(value);


    if (key === 'amount' && value != '0' && parseInt(value) > 0) {
      str = parseInt(str).toString();
    }

    var rest = str.length % 3;
    var part = Math.trunc(str.length / 3);

    if (str.length > 3) {
      output += str.substring(0, rest);
      str = str.slice(rest);

      for (var i = 0; i < part; i++) {
        output += ' ';
        output += str.substring(0, 3);
        str = str.slice(3);
      }
    } else {
      output = str;
    }

    switch (key) {
      case 'amount': {
        this.amount.setValue(output);
        break;
      }
      case 'phone': {
        this.isPaypal() ? this.phone.setValue(value) :this.phone.setValue(output);
        break;
      }
      case 'phoneConfirm': {
        this.isPaypal() ? this.phoneConfirm.setValue(value) :this.phoneConfirm.setValue(output);
        break;
      }
      case 'code': {
        this.code.setValue(output);
        break;
      }
    }
  }

  extractFees(debit: any) {
    if (Array.isArray(debit)) {
      for (var i = 0; i < debit.length; i++) {
        if (debit[i].hasOwnProperty('client')) return debit[i].amount;
      }
    } else {
      return debit.amount;
    }

    return null;
  }

  evaluateFees() {
    let data: {
      [k: string]: any
    } = {};
		data.type = (this.product.zone === 'agent') ? this.product.recharge ? 'recharge_'+this.service.slug : this.operation ? (this.operation.slug === 'cashout') ? 'client_api_withdraw' : 'client_api_paybill' : (this.product.category === 'cashout') ? this.service.category + '_' + this.service.slug : 'client_api_paybill': 'client_api_paybill';
    data.client1 = (this.product.zone === 'agent') ? this.product.recharge ? '237000000005' : (this.operation) ? this.service.client : null : this.user.phonecompte;
    data.merchantid = (this.operation) ? (this.operation.slug === 'cashin') ? this.operation.merchantid : this.service.merchantid : this.service.merchantid;
    data.merchantid = (this.product.category === 'cashout') ? null : data.merchantid;
    data.currency = 952;
    data.amount = parseInt(this._cleanString(this.amount.value));
    data.agentid = (data.type === 'client_api_withdraw') ? this.service.agentid : this.user.agent.idagent;

    if (this.product.zone === 'agent') {
      if (this.user.hasOwnProperty('agent')) {
        data.agentid = this.user.agent.idagent;
      }
      if (this.operation) {
        if (this.operation.slug === 'cashin') {
          data.client1 = null;
        }

        data.agentid = this.user.agent.idagent;
      }
    }


    var bool = true;


    if (!(data.amount > 0)) {
      bool = bool && false;
      this.amountError = true;
    } else {
      this.amountError = false;
    }

    if (this.service.slug === 'afrikpay') {
      var phone = this._cleanString(this.phone.value);
      let cc  = '';
      let regex = this.service.accountregex.toString();
      regex = regex.slice(1);
      regex = regex.substring(0, regex.length - 1);
      regex = new RegExp(regex, '');
      var isMatching = phone.match(regex) ? true : false;


      if(!this.service.wallet) {
				if (!(isMatching && phone.length > 5)) {
          bool = bool && false;
          this.phoneError = true;
        }

				if (!(this._cleanString(this.phone.value) === this._cleanString(this.phoneConfirm.value))) {
          bool = bool && false;
          this.phoneConfirmError = true;
        }

        cc = this.service.international ? this.country_code : '';

			}



      // data.type = 'client_api_transfer';
      if(this.operation) {
        if(this.operation.slug === 'cashin'){
          data.type = 'client_api_credit';
        }
      }

      data.client1 = cc + phone;
      data.merchantid = '2024654329067873';
    }


    if (bool) {

      this.setModal('processing', 'please_wait', 'evaluation');
      $('#modalPayment').modal('toggle');

      this.fees = 0;
      this.fees_agent = 0;

      this.http.getFees(data).subscribe((response: any) => {

          if (response) {
            if (response.debit) {
              if (this.extractFees(response.debit) !== null) {
                this.fees = Math.ceil(this.extractFees(response.debit));
              }
            }

            if (response.credit  && this.product.mode === 'agent') {
              if (this.extractFees(response.credit) !== null) {
                this.fees_agent = Math.trunc(this.extractFees(response.credit));
              }
            }
          }
          if (this.product.mode === 'agent') {
            this.total = null;
          } else {
            this.total = - this.fees - this.fees_agent + data.amount;
          }

          this.dismissModal(1000, null);
        },
        (error) => {

          this.dismissModal(1000, null);
        });
    }
  }

  resetFormErrors() {
    this.amountError = false;
    this.phoneError = false;
    this.phoneConfirmError = false;
  }

  fetchCode() {

    let amount =  parseInt(this._cleanString(this.amount.value));
    if(amount <= 100) {
      this.amountError = true;
      return;
    }

    this.setModal('processing', 'please_wait', 'sending_auth_code');
    $('#modalPayment').modal('toggle');

    var cc = this.service.international ? this.country_code : '';
    var reference = this.service.wallet ? this.service.wallet.account : cc+this._cleanString(this.phone.value);

    this.http.customRequest(this.urlgetcode, {reference, amount}).subscribe(() => {
      this.withCode = true;
      $('#modalPayment').modal('toggle');

      this.modalText = reference ;
      $("#modalPayment").modal("hide");
      $("#modalInfo").modal("show");

    })
  }

  toggleModal(action: number) {
    if (action === 2) {
      let bool = true;

      $('#modalDetails').modal('hide');

      if (!(parseInt(this._cleanString(this.amount.value)) > 0)) {
        bool = bool && false;
        this.amountError = true;
      }

      var phone = this._cleanString(this.phone.value);
      let regex = this.service.accountregex.toString();
      regex = regex.slice(1);
      regex = regex.substring(0, regex.length - 1);
      regex = new RegExp(regex, '');
      var isMatching = phone.match(regex) ? true : false;

      if(!this.service.wallet) {

        if (this.service.international) {
          if (!(phone.length === this.phoneLength)) {
            bool = bool && false;
            this.phoneError = true;
          }
        }

        if(this.isPaypal()) {

          var isMatching = this.phone.value.trim().match(regex) ? true : false;
          if (!(isMatching)) {
            bool = bool && false;
            this.phoneError = true;
          }

          if (!(this.phone.value.trim() === this.phoneConfirm.value.trim())) {
            bool = bool && false;
            this.phoneConfirmError = true;
          }
        } else {
          var isMatching = phone.match(regex) ? true : false;
          if (!(isMatching && phone.length > 4)) {
            bool = bool && false;
            this.phoneError = true;
          }

          if (!(this._cleanString(this.phone.value) === this._cleanString(this.phoneConfirm.value))) {
            bool = bool && false;
            this.phoneConfirmError = true;
          }
        }
			}

      if (this.withCode) {
        var code = this._cleanString(this.code.value);
        if (!(code.toString().length >= 4)) {
          bool = bool && false;
          this.codeError = true;
        }
      }

      if (bool) {


        if(this.getCode && !this.withCode) {
					this.fetchCode();
					return;
				}

        if (this.product.need_pin) {

          if (this.product.mode === 'client') {

            this.togglePad();

          } else {

            if(this.operation){

              if (this.operation.slug === 'cashin') {
                this.togglePad();
              } else {
                this.execute();
              }

            } else {
              this.togglePad();
            }
          }
        } else {
          this.execute();
        }
      }
    } else {
      if (action === 0) {
        this.togglePad();
      } else {
        if (action === 1) {
          this.execute();
        }
      }
    }
  }

  getRandomDigits() {
    return Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10);
  }

  retry() {
    $("#modalAlert").modal("hide");
    setTimeout(() => {
      this.execute();
    }, 300)
  }

  execute() {

    if (this.product.need_pin) {
      if (this.product.mode === 'client') {
        if(this.pinCode.length < 4  && this.product.need_pin) return;
      } else {
        if(this.operation) {
          if (this.operation.slug === 'cashin') {
            if(this.pinCode.length < 4  && this.product.need_pin) return;
          }
        }
      }
    }

    let data: {
      [k: string]: any
    } = {};

    this.statusOperation = "";
    this.totalTime = 0;
    let cc = !this.service.wallet ? this.service.international ? this.country_code : '' : '';
    data.operator = this.service.slug;
    data.reference = this.isPaypal() ? this.phone.value.trim() : cc + this._cleanString(this.phone.value);
    data.amount = parseInt(this._cleanString(this.amount.value));
    data.account = this.user.phonecompte;
    data.client = this.user.phonecompte;
    data.method = 'money';
    data.provider = this.service.slug;
    data.payref = this.isPaypal() ? this.phone.value.trim() : cc + this._cleanString(this.phone.value);
    data.currency = 952
    data.agentid = null;
    data.agentpwd = null;
    data.mode = this.product.mode === 'agent' ? 'cash' : null;
		data.type =  (this.product.recharge) ? 'recharge' : this.product.mode === 'agent' ? this.operation ?  this.operation.slug  : this.product.category : this.product.category;
    var date = new Date();
    data.processingnumber = date.getTime().toString() + this.getRandomDigits();
    this.transactionid = data.processingnumber;
		data.identify = environment.id;
		data.terminaltype = 'web';


    if (this.product.zone === 'agent') {

      data.agentid = this.user.agent.idagent;
      data.agentpwd = this.user.agent.password;

    }

    if (this.service.slug === 'afrikpay') {
      data.merchantid = '2024654329067873';
    }

    var api = this.product.api;
    var urlReq = this.urlReq;
    var slug = this.service.slug;
    var category = this.product.category

    if ((slug === 'cashout' || category === 'cashout' || data.type === 'cashout')) {console.log(this.code.value);
      data.code = this._cleanString(this.code.value);
      this.http.cashoutExec(data, api, urlReq, slug, category).subscribe(() => {
        this.totalTime = (this.service.delay || 10);
        setTimeout(() => {
          this.checkStatus();
        }, (this.service.delay * 1000 || 10000));
        return;
      });
    } else {

      data.code = this._cleanString(this.code.value);
      data.code = (this.product.zone === 'agent') ? Md5.hashStr(this.pinCode) : data.code;

      this.http.exec(
        data,
        api,
        urlReq,
        slug,
        category
      ).subscribe((response: any) => {
        if (response) {
          if (response.code === 200) {


            this.closeOperation();


          } else {

            this.modalText = response.message;
            $("#modalPayment").modal("hide");
            $("#modalAlert").modal("show");
          }
        } else {
          this.setModal('warning', 'transaction_failed', '');
          this.dismissModal(2500, null);
        }
      });
    }

    this.setModal('processing', 'please_wait', 'transaction_processing');
    $('#modalPayment').modal('toggle');

    $('#modalDetails').modal('hide');
    if(this.pPadVisible) this.togglePad();
  }



  addDigit(d: string) {
    if (this.pinCode.length < 4) {
      this.pinCode = this.pinCode + d
    }
  }

  deleteDigit() {
    (this.pinCode.length > 0) ? this.pinCode = this.pinCode.substring(0, this.pinCode.length - 1): null;
  }

  togglePad() {
    if(!this.pPadVisible) this.pinCode = "";
    this.pPadVisible = !this.pPadVisible;
    this.numpad = this.helper.getNumpad();
  }

  checkStatus() {

    this.http.getStatus(this.transactionid).subscribe((response: any) => {
      if (response) {

        if(response.code === 200){
          if (response.message) {

            var message = response.message.toString().toLowerCase();

            if (message === 'success') {

              this.closeOperation();

            }

            if (message === 'failed') {

              this.modalText = 'operation_denied';
              $("#modalPayment").modal("hide");
              $("#modalAlert").modal("show");

            }

            if (!(this.totalTime < 300)) {

              this.setModal('warning', 'timeout', '');
              this.dismissModal(2500, null);

            } else {

              if (!(message === 'success' || message === 'failed')) {

                if (response.code === 200) {
                  this.totalTime = this.totalTime + (this.service.delay || 10);
                  setTimeout(() => {
                    this.checkStatus();
                  }, (this.service.delay * 1000 || 10000));

                } else {

                  this.setModal('warning', 'failed', '');
                  this.dismissModal(2500, null);

                }
              }
            }

          }
        } else {
          this.modalText = 'operation_denied';
          $("#modalPayment").modal("hide");
          $("#modalAlert").modal("show");
        }
      }
    })
  }

  closeOperation() {
    this.setModal('success', 'successfull', '');

    this.dismissModal(1500, null);
    this.pinCode = "";

    setTimeout(() => {
      this.router.navigateByUrl('/home');
    }, 2500);

    return;
  }

  recheckName() {
    $("#modalDetails").modal("hide");
    setTimeout(() => {
      this.checkRecipient();
    }, 300)
  }

  checkRecipient() {

    let data: {
      [k: string]: any
    } = {};
    let cc = this.service.international ? this.country_code : '';
    data.account = this.isPaypal() ? this.phone.value.trim() :  cc + this._cleanString(this.phone.value);
    data.provider = this.service.slug;

    var bool = true;

    if (!(parseInt(this._cleanString(this.amount.value)) > 0)) {
      bool = bool && false;
      this.amountError = true;
    }



    var phone = this._cleanString(this.phone.value);
    let regex = this.service.accountregex.toString();
    regex = regex.slice(1);
    regex = regex.substring(0, regex.length - 1);
    regex = new RegExp(regex, '');

    if (this.service.international) {
      if (!(phone.length === this.phoneLength)) {
        bool = bool && false;
        this.phoneError = true;
      }
    }


    if(this.isPaypal()) {

      var isMatching = this.phone.value.trim().match(regex) ? true : false;
      if (!(isMatching)) {
        bool = bool && false;
        this.phoneError = true;
      }

			if (!(this.phone.value.trim() === this.phoneConfirm.value.trim())) {
				bool = bool && false;
        this.phoneConfirmError = true;
			}
		} else {
      var isMatching = phone.match(regex) ? true : false;
      if (!(isMatching && phone.length > 4)) {
        bool = bool && false;
        this.phoneError = true;
      }

			if (!(this._cleanString(this.phone.value) === this._cleanString(this.phoneConfirm.value))) {
        bool = bool && false;
        this.phoneConfirmError = true;
      }
		}


    if (bool) {
      this.showConfirmBtn = true;

      this.setModal('processing', 'please_wait', 'checking');
      $('#modalPayment').modal('toggle');

      this.http.getRecipient(data).subscribe((response: any) => {
        if (response) {
          if (response.code === 200 || response.code === 404) {

            if (response.code === 404) this.showConfirmBtn = false;
            this.recipient = response.name;
            this.dismissModal(1000, null);
            $('#modalDetails').modal('toggle');

          } else {
            this.setModal('danger', response.message, '');
            this.dismissModal(2500, null);
          }
        } else {
          this.setModal('warning', 'failed', '');
          this.dismissModal(2500, null);
        }
      });
    }
  }

  isMobile(){
    return this.helper.isMobile();
  }

}
