import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { slideInOutAnimation2 } from '../_animations';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../helper.service';
import { HttpService } from '../http.service';
import { StoreService } from '../store.service';
import {FormControl} from '@angular/forms';
declare var $ : any;

@Component({
  selector: 'app-history-export',
  templateUrl: './history-export.component.html',
  styleUrls: ['./history-export.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation2],

  // attach the slide in/out animation to the host (root) element of this component
  host: {
    '[@slideInOutAnimation2]': ''
  }
})
export class HistoryExportComponent implements OnInit {
  user : any;
  startDate = new FormControl("");
  endDate = new FormControl("");
  reference = new FormControl("");
  description = new FormControl("");

  modalTitle: string;
  modalText: string;
  modalType: string;

  endDateError = false;
  startDateError = false;
  referenceError = false;
  descriptionError = false;
  retry = false;

  constructor(private helper : HelperService, private store : StoreService, private http : HttpService,
    private translate : TranslateService, private router : Router) { }

  ngOnInit() {
    this.user = this.store.getUser();
  }

  resetError(){
    this.startDateError = false;
    this.endDateError = false;
  }


  export(){
    let start = this.startDate.value;
    let end = this.endDate.value;

    let data = {
      begindate : this.startDate.value,
      enddate : this.endDate.value,
      reference : this.reference.value,
      description : this.description.value,
      agentid : this.user.agent.idagent
    }


    if(!(this.checkdate(data.begindate))) {
      this.startDateError = true;
      return
    }

    if(!(this.checkdate(data.enddate))) {
      this.endDateError = true;
      return
    }

    this.retry = false;
    this.setModal('processing', 'please_wait', 'processing');
    $('#modalExport').modal('toggle');
    $('#modalExportAlert').modal("hide");


    this.http.export(data).subscribe((response : any) => {
      $('#modalExport').modal('toggle');

      if(response){

        this.modalText = response.message;

        if(response.code == 200) {

          this.modalTitle = "successfull";
          $('#modalExportAlert').modal('toggle');

        } else {
          this.modalTitle = "failed";
          $('#modalExportAlert').modal('toggle');
          this.retry = true;
        }
      } else {
          this.modalTitle = "failed";
          this.modalText = 'failed';
          $('#modalExportAlert').modal('toggle');
      }
    })


  }


  checkdate(d : any){
    return new Date(d).toString() !== 'Invalid Date';
  }


  isMobile(){
    return this.helper.isMobile();
  }

  setModal(type : string, text : string, title : string) {

    this.modalType = type;
    this.modalTitle = title;

    switch (type) {
      case 'danger': {
        this.modalText = text;
        break;
      }

      default:{
        this.translate.get(text).subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }
    }

  }

}
