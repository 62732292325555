export const environment = {
  production: false,
  targetAPI: 'https://customer-middleware.afrikpay.com/',
  agentid : '3954375269081090',
  agentpwd : '3954@1090$',
  apikey : '92f1fabb6353960d51d5a2f7e39e52b0',
  url : 'https://camed.afrikpay.com',
  id : 'camed.afrikpay.com',
  accepturl : 'https://camed.afrikpay.com/payment/web/success',
  cancelurl : 'https://camed.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.afrikpay.com/print/",
  storage : 'home/oss'
};