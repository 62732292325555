import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
// import slide in/out animation
import { slideInOutAnimation,fadeInAnimation } from '../_animations/index';
import {HttpService} from '../http.service';
import { HelperService } from '../helper.service';
import { User } from '../classes';
import { StoreService } from '../store.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation, fadeInAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '', '[@fadeInAnimation]' : '' }
})
export class CategoryComponent implements OnInit {

  products = [
    {
      title: 'airtime_internet',
      second_title: 'buy_airtime',
      item_header: 'recharge',
      api: 'airtime',
      category: 'airtime',
      mode: 'agent',
      zone : 'agent',
      payment_view: 'printer',
      icon : "<span class='icon ion-ios-cellular text-primary' style='font-size: 2rem !important;'></span>"
    },
    {
      title: 'mobile_money',
      second_title: 'mobile_money',
      item_header: 'transaction',
      api: 'recharge',
      mode: 'agent',
      zone : 'agent',
      category: null,
      payment_view: 'printer',
      icon : "<span class='mdi mdi-cash-usd-outline text-primary' style=\"font-size: 2rem !important;\"></span>"
    },
    {
      title: 'tuition_fees',
      second_title: 'tuition_fees',
      item_header: 'pay_fees_for',
      api: 'bill',
      category: 'school',
      mode: 'agent',
      zone : 'agent',
      payment_view: 'printer',
      icon : "<span class='mdi mdi-school text-primary' style='font-size: 2rem !important;'></span>"
    },
    {
      title: 'pay_bill',
      second_title: 'bill_payment_subs',
      api: 'bill',
      item_header: '',
      category: 'bill',
      mode: 'agent',
      zone : 'agent',
      payment_view: 'printer',
      icon : "<span class='mdi mdi-receipt text-primary' style='font-size: 2rem !important;'></span>"
    },
    {
      title: 'services',
      second_title: 'services',
      item_header: 'service_of',
      api: 'bill',
      mode: 'agent',
      zone : 'agent',
      category: 'service',
      payment_view: 'printer',
      icon : "<span class='mdi mdi-widgets text-primary' style='font-size: 2rem !important;'></span>"
    }
  ];

  constructor(private http : HttpService, private router : Router, private helper : HelperService, private store : StoreService) { }

  ngOnInit() {
  }


  goTo(product){
    // this.router.navigateByUrl('home/category/services/' , {state : {product}});
    this.router.navigate(['home/services/'], {state : {product}});
  }


  isMobile(){
    return this.helper.isMobile();
  }

}
