import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import {Md5} from 'ts-md5/dist/md5';
import { User } from '../classes';
import { HelperService } from '../helper.service';
import { HttpService } from '../http.service';
import { StoreService } from '../store.service';
// import slide in/out animation
import { slideInOutAnimation2 } from '../_animations/index';
declare var $:any;

@Component({
  selector: 'app-payment-sub-bills',
  templateUrl: './payment-sub-bills.component.html',
  styleUrls: ['./payment-sub-bills.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation2],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation2]': '' }
})
export class PaymentSubBillsComponent implements OnInit {

  modalTitle: string;
  modalText: string;
  modalType: string;
  product = null;
  service: any;
  loading = true;
  lang: string;
  withCode = false;
  withMobile = false;
  fees: number;
  user: User;
  amount: FormControl;
  billid: string;
  billidConfirm: FormControl;
  mobile: FormControl;

  amountError = false;
  billidError = false;
  billidConfirmError = false;
  mobileError = false;

  country_code = '237';
  phoneLength = 9;
  pinCode: string;

  total: number;
  urlReq: string;

  pPadVisible = false;
  numpad = [];
  refDetails: any;
  countries = [];

  action_out = false;

  manual = false;
  pinCode_temp : string;
  options  = [];

  item : any;

  constructor(private http: HttpService, private helper: HelperService, private store: StoreService,
    private translate: TranslateService, private router: Router) {}

  ngOnInit() {
    this.product = window.history.state.product;
    this.user = this.store.getUser();
    this.service = window.history.state.service;
    this.billid = window.history.state.billid;


    if(!this.product || !this.service || !this.billid) this.router.navigateByUrl('/home');

    this.lang = localStorage.getItem('lang');
    this.pinCode = "";
    this.showSubBills();
  }

  setModal(type : string, text : string, title : string) {

    this.modalType = type;
    this.modalTitle = title;

    switch (type) {
      case 'danger': {
        this.modalText = text;
        break;
      }

      default:{
        this.translate.get(text).subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }
    }

  }

  _cleanString(str: any) {
    return str.replace(/\s/g, '');
  }

  _numberise(str: any) {
    str = this._cleanString(str);

    var output = '';

    for (var i = 0; i < str.length; i++) {
      if (!isNaN(str[i])) output += str[i];
    }

    return output;
  }

  onChangeText($event: any, key: string) {
    var value = $event.target.value;
    var output = '';
    var str = this._cleanString(value);


    if (key === 'amount' && value != '0' && parseInt(value) > 0) {
      str = parseInt(this._numberise(str)).toString();
    }

    var rest = str.length % 3;
    var part = Math.trunc(str.length / 3);

    if (str.length > 3) {
      output += str.substring(0, rest);
      str = str.slice(rest);

      for (var i = 0; i < part; i++) {
        output += ' ';
        output += str.substring(0, 3);
        str = str.slice(3);
      }
    } else {
      output = str;
    }

    switch (key) {
      case 'amount': {
        this.amount.setValue(output);
        break;
      }
    }
  }

  retry_listing() {
    setTimeout(() => {
      this.showSubBills();
    }, 300)
  }

  cancel_listing() {
    this.dismissModal(0, null);
  }

  retry() {
    this.togglePad();
  }


  showSubBills(){
    $("#modalBillsAlert").modal("hide");
    this.setModal('processing', 'please_wait', 'checking');
    $('#modalSubBillPayment').modal('toggle');

		this.http.getData( this.service.slug, this.billid, 'GetBill').subscribe((response : any) => {
			if (response) {
				if (response.code === 200) {
          $('#modalSubBillPayment').modal('hide');
					this.options = response.options;
				} else {
          this.modalText = response.message;
          $("#modalSubBillPayment").modal("hide");
          $("#modalBillsAlert").modal("show");
				}
			} else {
        this.setModal('danger', 'failed', '');
        this.dismissModal(2500, null);
			}
		});
  }


  pay_bill(data) {
    this.http.exec(
      data,
      this.product.api,
      this.urlReq,
      this.service.slug,
      this.product.category
    ).subscribe((response: any) => {
      if (response) {
        if (response.code === 200) {

          this.setModal('success', 'successfull', '');

          this.dismissModal(2500, 'home');
          this.pinCode = "";


        } else {

          this.modalText = response.message;
          $("#modalSubBillPayment").modal("hide");
          $("#modalBillAlert").modal("show");
        }
      } else {
        this.setModal('danger', 'failed', '');
        this.dismissModal(2500, null);
      }
    });
  }



  execute() {

    if(this.pinCode.length < 4) return;

    let data: {
      [k: string]: any
    } = {};

    this.pinCode_temp = this.pinCode;
    data.code = Md5.hashStr(this.pinCode).toString();
    if(this.pPadVisible) this.togglePad();
    data.biller = this.service.slug;
    data.billid = this.billid;
    data.option = this.item.id;
    data.amount = this.item.amount;
    data.account = this.user.phonecompte;
    data.currency = 952;
		data.identify = environment.id;
		data.terminaltype = 'web';
    data.agentid = this.user.agent.idagent;
    data.agentpwd = this.user.agent.password;

    this.setModal('processing', 'please_wait', 'transaction_processing');
    $('#modalSubBillPayment').modal('toggle');

    this.pay_bill(data);

    // this.http.login(
    //   this.user.agent.username,
    //   data.code, this.lang
    // ).subscribe((response: any) => {
    //   if (response) {
    //     if (response.code === 200) {

    //       this.pay_bill(data);

    //     } else {

    //       this.modalText = 'invalid_scret_code';
    //       $("#modalSubBillPayment").modal("hide");
    //       $("#modalBillAlert").modal("show");
    //     }
    //   } else {
    //     this.setModal('danger', 'failed', '');
    //     this.dismissModal(2500, null);
    //   }
    // });
  }

  toggleModal(action, item) {
		if (action === 0) {
			this.pPadVisible = true;
		} else {
			if (action === 1) {
				if(this.pinCode.length < 4 ) return;
				this.execute();
			} else {
        this.item = item;
        this.togglePad();
			}
		}
  }


  addDigit(d: string) {
    if (this.pinCode.length < 4) {
      this.pinCode = this.pinCode + d
    }
  }

  deleteDigit() {
    (this.pinCode.length > 0) ? this.pinCode = this.pinCode.substring(0, this.pinCode.length - 1): null;
  }

  togglePad() {
    this.pPadVisible = !this.pPadVisible;
    this.numpad = this.helper.getNumpad();
    this.pinCode = "";
  }


  isMobile(){
    return this.helper.isMobile();
  }

  dismissModal(timer: number, destination) {
    setTimeout(() => {
      $('#modalSubBillPayment').modal('hide');
      $('#modalBillsAlert').modal('hide');
      $('#modalBillAlert').modal('hide');
      if(destination) {
        this.router.navigateByUrl(destination);
        return;
      }
      window.history.back();
    }, timer);
  }

}
